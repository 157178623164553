/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.card-specific {
  max-width: 345;
  margin-top: 50px;
  padding-bottom: 10px;
}

/* .text-field {
  margin-top: 20px !important;
  width: 100%;
} */

.check-box {
  margin-top: 20px !important;
}

.error {
  color: red;
}

.cancel-link {
  color: rgb(121, 121, 121) !important;
  text-decoration: none;
}

[id*='feedback_minimized'] {
  bottom: 250px !important;
}
